exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-tools-[gradient]-backgrounds-gradient-js": () => import("./../../../src/pages/tools/gradient-backgrounds/[gradient].js" /* webpackChunkName: "component---src-pages-tools-[gradient]-backgrounds-gradient-js" */),
  "component---src-pages-tools-binary-code-translator-js": () => import("./../../../src/pages/tools/binary-code-translator.js" /* webpackChunkName: "component---src-pages-tools-binary-code-translator-js" */),
  "component---src-pages-tools-bold-text-generator-js": () => import("./../../../src/pages/tools/bold-text-generator.js" /* webpackChunkName: "component---src-pages-tools-bold-text-generator-js" */),
  "component---src-pages-tools-color-pallets-[id]-js": () => import("./../../../src/pages/tools/color-pallets/[id].js" /* webpackChunkName: "component---src-pages-tools-color-pallets-[id]-js" */),
  "component---src-pages-tools-convert-case-js": () => import("./../../../src/pages/tools/convert-case.js" /* webpackChunkName: "component---src-pages-tools-convert-case-js" */),
  "component---src-pages-tools-csv-to-json-js": () => import("./../../../src/pages/tools/csv-to-json.js" /* webpackChunkName: "component---src-pages-tools-csv-to-json-js" */),
  "component---src-pages-tools-gst-calculator-js": () => import("./../../../src/pages/tools/gst-calculator.js" /* webpackChunkName: "component---src-pages-tools-gst-calculator-js" */),
  "component---src-pages-tools-index-js": () => import("./../../../src/pages/tools/index.js" /* webpackChunkName: "component---src-pages-tools-index-js" */),
  "component---src-pages-tools-italic-text-generator-js": () => import("./../../../src/pages/tools/italic-text-generator.js" /* webpackChunkName: "component---src-pages-tools-italic-text-generator-js" */),
  "component---src-pages-tools-javascript-to-typescript-js": () => import("./../../../src/pages/tools/javascript-to-typescript.js" /* webpackChunkName: "component---src-pages-tools-javascript-to-typescript-js" */),
  "component---src-pages-tools-jpg-to-webp-js": () => import("./../../../src/pages/tools/jpg-to-webp.js" /* webpackChunkName: "component---src-pages-tools-jpg-to-webp-js" */),
  "component---src-pages-tools-json-parser-js": () => import("./../../../src/pages/tools/json-parser.js" /* webpackChunkName: "component---src-pages-tools-json-parser-js" */),
  "component---src-pages-tools-json-to-csv-js": () => import("./../../../src/pages/tools/json-to-csv.js" /* webpackChunkName: "component---src-pages-tools-json-to-csv-js" */),
  "component---src-pages-tools-loan-emi-calculator-js": () => import("./../../../src/pages/tools/loan-emi-calculator.js" /* webpackChunkName: "component---src-pages-tools-loan-emi-calculator-js" */),
  "component---src-pages-tools-pascal-case-generator-js": () => import("./../../../src/pages/tools/pascal-case-generator.js" /* webpackChunkName: "component---src-pages-tools-pascal-case-generator-js" */),
  "component---src-pages-tools-png-to-webp-js": () => import("./../../../src/pages/tools/png-to-webp.js" /* webpackChunkName: "component---src-pages-tools-png-to-webp-js" */),
  "component---src-pages-tools-qr-code-js": () => import("./../../../src/pages/tools/qr-code.js" /* webpackChunkName: "component---src-pages-tools-qr-code-js" */),
  "component---src-pages-tools-repeat-text-js": () => import("./../../../src/pages/tools/repeat-text.js" /* webpackChunkName: "component---src-pages-tools-repeat-text-js" */),
  "component---src-pages-tools-replace-text-generator-js": () => import("./../../../src/pages/tools/replace-text-generator.js" /* webpackChunkName: "component---src-pages-tools-replace-text-generator-js" */),
  "component---src-pages-tools-reverse-text-generator-js": () => import("./../../../src/pages/tools/reverse-text-generator.js" /* webpackChunkName: "component---src-pages-tools-reverse-text-generator-js" */),
  "component---src-pages-tools-slugify-url-generator-js": () => import("./../../../src/pages/tools/Slugify-URL-Generator.js" /* webpackChunkName: "component---src-pages-tools-slugify-url-generator-js" */),
  "component---src-pages-tools-snake-case-generator-js": () => import("./../../../src/pages/tools/snake-case-generator.js" /* webpackChunkName: "component---src-pages-tools-snake-case-generator-js" */),
  "component---src-pages-tools-strikethrough-text-generator-js": () => import("./../../../src/pages/tools/strikethrough-text-generator.js" /* webpackChunkName: "component---src-pages-tools-strikethrough-text-generator-js" */),
  "component---src-pages-tools-upside-down-text-generator-js": () => import("./../../../src/pages/tools/upside-down-text-generator.js" /* webpackChunkName: "component---src-pages-tools-upside-down-text-generator-js" */),
  "component---src-pages-tools-webp-to-jpg-js": () => import("./../../../src/pages/tools/webp-to-jpg.js" /* webpackChunkName: "component---src-pages-tools-webp-to-jpg-js" */),
  "component---src-pages-tools-webp-to-png-js": () => import("./../../../src/pages/tools/webp-to-png.js" /* webpackChunkName: "component---src-pages-tools-webp-to-png-js" */),
  "component---src-pages-tools-wide-text-generator-js": () => import("./../../../src/pages/tools/wide-text-generator.js" /* webpackChunkName: "component---src-pages-tools-wide-text-generator-js" */),
  "component---src-pages-tools-youtube-thumbnail-downloader-js": () => import("./../../../src/pages/tools/youtube-thumbnail-downloader.js" /* webpackChunkName: "component---src-pages-tools-youtube-thumbnail-downloader-js" */),
  "component---src-templates-careers-details-template-js": () => import("./../../../src/templates/careers-details-template.js" /* webpackChunkName: "component---src-templates-careers-details-template-js" */),
  "component---src-templates-industry-details-template-js": () => import("./../../../src/templates/industry-details-template.js" /* webpackChunkName: "component---src-templates-industry-details-template-js" */),
  "component---src-templates-project-details-template-js": () => import("./../../../src/templates/project-details-template.js" /* webpackChunkName: "component---src-templates-project-details-template-js" */),
  "component---src-templates-service-details-template-js": () => import("./../../../src/templates/service-details-template.js" /* webpackChunkName: "component---src-templates-service-details-template-js" */)
}

